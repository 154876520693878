<!--  -->
<template>
    <div class="myTemplate flex-column-center">
        <div>色系</div>
        <div class="flex-layout">
            <el-radio v-model="radio" label="RGB">RGB</el-radio>
            <el-radio v-model="radio" label="CMYK">CMYK</el-radio>
        </div>
        <div>文字:(哪个汉字)</div>
        <el-input placeholder="请输入char字段" v-model="input" clearable>
        </el-input>
        <div>尺寸:(整图宽,整图高)</div>
        <el-input placeholder="请输入图片尺寸" v-model="imgSize" clearable>
        </el-input>
        <div>文本:(文本,x,y,字体,颜色(RGB),字号,加粗,每行字符数,行距字号比例)</div>
        <el-input type="textarea" :rows="2" placeholder="请输入textStr字段" v-model="textarea1">
        </el-input>
        <div>圆角矩形:((x1,y1,x2,y2),圆角度,外边框线条宽,颜色(RGB))</div>
        <el-input type="textarea" :rows="2" placeholder="请输入roundedRectangleStr字段" v-model="textarea2">
        </el-input>
        <div>汉字框:(x,y,宽,高,列,行,格宽,外边框线条宽,颜色(RGB),是否有虚线)</div>
        <el-input type="textarea" :rows="2" placeholder="请输入boxStr字段" v-model="textarea3">
        </el-input>
        <div>图片:(x,y,高)</div>
        <el-input type="textarea" :rows="2" placeholder="请输入imgStr字段" v-model="textarea4">
        </el-input>
        <el-button type="success" @click="send">发送</el-button>
        <a :href="pdfUrl" target="_blank">下载PDF</a>
        <div class="block" v-if="imgSrc">
            <el-image style="width: 700px;margin-top:20px" :src="imgSrc">
            </el-image>
        </div>
    </div>
</template>
<script>
    import axios from 'axios'
    export default {
        data() {
            return {
                input: '一',
                imgSize: `2100,2970`,
                textarea1: `('--请将个人条码粘贴到本框内--',790,560,'SIMYOU.TTF',(0,0,0),34,0,9999,1)`,
                textarea2: `((670,460,1380,680),15,2,(11,11,11))`,
                textarea3: `(332,2530,1780,200,1,5,150,2,(0,0,0),1)`,
                textarea4: `('img/background/平谷学生前测卷/平谷学生前测卷一下1.jpg',0  ,0,2970)`,
                imgSrc: '',
                pdfUrl: '',
                radio: 'RGB'
            }
        },
        methods: {
            send() {
                let data = {
                    char: this.input,
                    imgSize: this.imgSize,
                    textStr: this.textarea1,
                    roundedRectangleStr: this.textarea2,
                    boxStr: this.textarea3,
                    imgStr: this.textarea4,
                    color_mode:this.radio
                }
                console.log('调整模板参数--', data)
                this.$Api.Login.template(data)
                    .then(res => {
                        this.$message({
                            message: '发送成功',
                            type: 'success'
                        });
                        // https://wstestv2.lezhireading.com:30443/api/python/zitie/static/1671113707.4945905.jpg
                        console.log('测试调整模板成功--', res);
                        this.imgSrc = this.commonbaseURL + '/python/zitie/' + res
                        this.pdfUrl = this.commonbaseURL + '/python/zitie/pdf/' + res
                    })
                    .catch(err => {
                        this.$message.error('发送失败');
                        console.log('测试调整模板失败--', err)
                    })
            },
        },
        mounted() {},
    };
</script>
<style lang='less' scoped>
    @import "./template.less";
</style>